import React, { useContext, Fragment } from "react"
import { graphql } from "gatsby"
import uniq from "lodash.uniq"

/* Import Global Context(s) */
import ProjectsContext from "~context/projects"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"
import Filters from "~components/filters/filters"
import ProjectsTable from "~components/projectsTable/projectsTable"

/* Import Local Style(s) */
import "./format.css"

const Projects = ({ data, pageContext }) => {
  const [projects] = useContext(ProjectsContext)
  const selected = pageContext.format
  const options = uniq(data.allSanityProject.distinct).map(format => {
    return { id: 0, title: format }
  })

  const filteredProjects = projects.filter(project => {
    if (project.format.length === 0 || project.displayOnSite === false) {
      return false
    }
    return project.format === pageContext.format
  })

  return (
    <Fragment>
      <SEO title={`Format`} />
      <Page title="projects">
        {/* keyword */}
        <Filters type="format" selected={selected} options={options} />
        {/* keywords */}
        <ProjectsTable projects={filteredProjects} />
      </Page>
    </Fragment>
  )
}

export default Projects

export const query = graphql`
  query {
    allSanityProject(filter: { displayOnSite: { eq: true } }) {
      distinct(field: format)
    }
  }
`
